/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { schemaForm } from "./category.model";
import VirtualizedSelect from "react-virtualized-select";
import axios from "axios";
import { BtoA } from "../../helpers";

function KategoriFE02() {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [nextPage, setNextPage] = useState([]);
  const [clients, setClients] = useState([]); //eslint-disable-line
  // const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    getData();
    getSetting();
    getCLient()
    setResult([]);
  }, []);

  const getCLient = async (file) => {
    try {
      const userSave = localStorage.getItem("credential");
      const btoa = BtoA("kocak123");
      const username = btoa(userSave);
      const password = "password";
      const result = await axios.post(
        "https://api.setting.berkah-ts.my.id/oauth/token",
        new URLSearchParams({
          username: username,
          password: password,
          grant_type: "password",
        }),
        {
          headers: {
            Authorization:
              "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
          },
        }
      );

      const token = result.data.access_token;

      const resClient = await axios.post(
        `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
        {
          desc: "baseurl",
          value: JSON.stringify({
            clientid: "",
          }),
        }
      );

      console.log(resClient?.data?.lobj, "ini ko");

      setClients(resClient?.data?.lobj[0]);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({ key: "rekomendasiproduk", method: "get" })
      )
    );
    const result = res?.data?.data;
    setResult(result || []);
  };

  const getSetting = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    const token = result.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "systemmenu",
        value: JSON.stringify({ clientId: username }),
      }
    );
    let resultMextPage = resultUrl.data.lobj[0].nextpage;

    //split nextpage with delimiter -
    resultMextPage = resultMextPage.split("-");

    setNextPage(resultMextPage);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setCurrentEditIndex(null); // Reset the current edit index
    formHandler.reset();
  };

  const initialValues = useMemo(() => {
    return {
      header: "",
      produk: [],
    };
  }, []);

  const formHandler = useForm({
    resolver: zodResolver(schemaForm),
    values: initialValues,
  });

  const produk = useFieldArray({
    control: formHandler.control,
    name: "produk",
  });

  const { errors, touchedFields } = formHandler.formState;

  const onSubmit = useCallback(
    async (values) => {
      // Check if we're editing an existing category
      if (isEdit && currentEditIndex !== null) {
        // Edit existing entry
        const updatedResult = [...result];
        updatedResult[currentEditIndex] = {
          header: values.header,
          produk: values.produk,
        };
        saveAction(updatedResult, updatedResult, true, false); // Pass `true` for edit
      } else {
        // New entry
        saveAction(values, result, isEdit, false);
      }
    },
    [result, isEdit]
  );

  const uploadImage = async (data, index) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    produk.update(index, {
      ...produk.fields[index],
      urlimg: res.file,
    });
  };

  const saveAction = useCallback(async (payload, res, edit, isDelete) => {
    if (
      !Array.isArray(payload) &&
      res.map((v) => v?.header).includes(payload?.header) &&
      !edit
    ) {
      toast.error("Kategori tidak boleh sama");
    } else {
      let dataSend = [];
      if (isDelete || edit) {
        dataSend = payload;
      } else {
        dataSend = [...res, payload];
      }
      const jsonSend = {
        value: {
          rekomendasi: dataSend,
        },
      };

      const value = JSON.stringify(jsonSend);

      const result = await dispatch(
        _fetch(
          BridgeService.JbMarket({
            key: "rekomendasiproduk/update",
            method: "post",
            payload: value,
          })
        )
      );
      if (result.data.success) {
        getData();
      }
      formHandler.reset();
      setIsEdit(false);
    }
  }, []);
  const deleteAction = async (v, index) => {
    const newResult = result.filter((item) => item.header !== v.header);
    saveAction(newResult, result, isEdit, true);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="kategori-fe02" />
          <Content>
            <PageHeading title="Kategori FE02" />
            {/* {!selectedClient ? (
              <div className="form-group">
                <label>Klien</label>
                <select
                  className="form-control"
                  name="type"
                  onChange={(e) => {
                    setSelectedClient(e.target.value);
                  }}
                  value={selectedClient}
                >
                  <option value="">Pilih Client</option>
                  {clients?.map((item, index) => {
                    return (
                      <option key={index} value={item?.value}>
                        {item?.idapps?.split(".")[2]}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : ( */}
              <div className="row">
                <div className="col-12">
                  <Card>
                    {/* <Input
                    label="ID"
                    onChange={handleChange("id")}
                    placeholder="ID"
                    value={form?.id}
                  /> */}
                    <Input
                      label="Nama Kategori"
                      inputProps={{
                        ...formHandler.register("header"),
                      }}
                      placeholder="Nama Kategori"
                      error={touchedFields.header && errors.header?.message}
                    />
                    <div className="d-flex mb-3 cursor-pointer">
                      <i className="fa fa-plus my-auto"></i>
                      <span
                        className="text-secondary my-auto ml-2"
                        onClick={() => {
                          produk.append({
                            nama: "",
                            harga: 0,
                            link: "",
                            urlimg: "",
                          });
                        }}
                      >
                        Tambah produk
                      </span>
                    </div>
                    {produk.fields.length > 0 && (
                      <div className="d-flex">
                        <table className="table table-hover order-column full-width">
                          <thead>
                            <tr>
                              <th className=""> No. </th>
                              <th className=""> Produk </th>
                              <th className=""> Harga </th>
                              <th className=""> Gambar </th>
                              <th className=""> Next Page </th>
                            </tr>
                          </thead>
                          <tbody>
                            {produk.fields?.map((item, index) => {
                              return (
                                <tr className="odd gradeX" key={index}>
                                  <td className="">{index + 1}</td>
                                  <td className="">
                                    <Input
                                      // label="Nama Produk"
                                      onChange={(e) => {
                                        const value = e.target?.value;
                                        produk.update(index, {
                                          ...item,
                                          nama: value,
                                        });
                                      }}
                                      noMb={true}
                                      containerStyle={{
                                        marginBottom: 3,
                                        marginRight: 10,
                                      }}
                                      value={item?.nama}
                                      error={
                                        errors.produk &&
                                        errors?.produk[index]?.nama?.message
                                      }
                                    />
                                  </td>
                                  <td className="">
                                    <Input
                                      type="number"
                                      // label="Harga"
                                      onChange={(e) => {
                                        const value = e.target?.value;
                                        produk.update(index, {
                                          ...item,
                                          harga: +value,
                                        });
                                      }}
                                      noMb={true}
                                      containerStyle={{
                                        marginBottom: 3,
                                        marginRight: 10,
                                      }}
                                      value={item?.harga}
                                      error={
                                        errors.produk &&
                                        errors?.produk[index]?.harga?.message
                                      }
                                    />
                                  </td>
                                  <td className="">
                                    {item.urlimg ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "9.5rem",
                                          padding: 10,
                                          border: "2px solid #d2d6de",
                                          backgroundColor:
                                            "rgba(210, 210, 210, 0.25)",
                                          color: "#ffffff",
                                        }}
                                        className="mb-3 d-flex flex-column"
                                      >
                                        <img
                                          src={item.urlimg}
                                          alt=""
                                          className="img-thumbnail mb-2"
                                          style={{
                                            height: "8rem",
                                            width: "8rem",
                                            objectFit: "contain",
                                          }}
                                        />
                                        <div
                                          style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            borderRadius: 99,
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          className="text-center d-flex justify-content-center align-items-center"
                                          // onClick={() => deleteImage()}
                                        >
                                          <i
                                            className="material-icons"
                                            style={{
                                              color: "#E44F56",
                                              cursor: "pointer",
                                            }}
                                          >
                                            cancel
                                          </i>
                                        </div>
                                      </div>
                                    ) : (
                                      <Input
                                        accept="image/*"
                                        // label="Gambar"
                                        type="file"
                                        containerStyle={{
                                          marginBottom: 3,
                                          marginRight: 10,
                                        }}
                                        className="my-auto"
                                        noMb={true}
                                        onChange={(e) =>
                                          uploadImage(
                                            e?.target?.files[0],
                                            index
                                          )
                                        }
                                      />
                                    )}
                                  </td>
                                  <td className="">
                                    <VirtualizedSelect
                                      id="nextpage"
                                      name="nextpage"
                                      className="my-auto"
                                      placeholder="Next Page"
                                      onChange={(e) =>
                                        produk.update(index, {
                                          ...item,
                                          link: e.value,
                                        })
                                      }
                                      value={item.link}
                                      options={nextPage.map((item, index) => ({
                                        label: item,
                                        value: item,
                                      }))}
                                    ></VirtualizedSelect>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div className="d-flex justify-content-center align-items-end">
                      <Button
                        title={"Simpan"}
                        type="warning"
                        onClick={formHandler.handleSubmit(onSubmit)}
                      />
                      {isEdit && (
                        <Button
                          title="Batal Edit"
                          type="danger"
                          onClick={() => cancelEdit()}
                          style={{ width: 130, marginLeft: 10 }}
                        />
                      )}
                    </div>
                  </Card>
                  <Card>
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th className=""> No </th>
                          <th className=""> Nama Kategori </th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.map((item, index) => {
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td className="">{index + 1}</td>
                              <td className="">{item.header}</td>
                              <td className="center">
                                <a
                                  className="btn btn-tbl-edit-no-round btn-xs"
                                  onClick={() => {
                                    setIsEdit(true);
                                    setCurrentEditIndex(index); // Set the current edit index
                                    formHandler.setValue("header", item.header);
                                    formHandler.setValue("produk", item.produk);
                                  }}
                                >
                                  <i className="fa fa-pencil"></i> Edit
                                </a>
                                <a
                                  className="btn btn-tbl-delete btn-xs"
                                  onClick={() => {
                                    deleteAction(item, index);
                                  }}
                                >
                                  <i className="fa fa-trash-o "></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                </div>
              </div>
            {/* )} */}
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(KategoriFE02);
